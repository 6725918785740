<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>操作说明</h5>
				<p>整理中，稍后发布</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>